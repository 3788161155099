import {
  TypesActionsFoods,
  LIST_FOODS,
  DELETE_FOOD,
  ADD_FOOD,
  UPDATE_FOOD,
  GET_FOODS,
} from '../../actions/foods/FoodsTypes';
import { FoodsState } from '../../models/foods/FoodsState';

const initialState: FoodsState = {
  foods: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0,
  },
  food: {
    _id: '',
    account: '',
    complements: [],
    createdAt: '',
    createdBy: '',
    deletedAt: null,
    description: '',
    discountPercent: 0,
    foodCategoryId: [],
    image: '',
    name: '',
    price: 0,
    status: '',
    timeStampId: 0,
    quantity: 0,
    quantityToPay: 0,
    serviceFee: 0,
    servicePercentage: 0,
    vatFee: 0,
    vatPercentage: 0,
    orderStatus: '',
    otherFee: 0,
    otherPercentage: 0,
    discountFee: 0,
    otherPercentageToPay: 0,
    servicePercentageToPay: 0,
    vatPercentageToPay: 0,
    otherFeeToPay: 0,
    vatFeeToPay: 0,
    discountFeeToPay: 0,
    subTotalToPay: 0,
    serviceFeeToPay: 0,
    totalToPay: 0,
  },
  foodsArray: [],
};

export default function foodsReducer(
  state = initialState,
  action: TypesActionsFoods
) {
  try {
    switch (action.type) {
      case LIST_FOODS: {
        const foods = action.payload;
        return {
          ...state,
          foods: foods,
        };
        return state;
      }
      case DELETE_FOOD: {
        const food = action.payload;
        return {
          ...state,
          foods: {
            docs: [...state.foods.docs.filter((e) => e._id !== food)],
          },
        };
      }
      case UPDATE_FOOD: {
        const food = action.payload;
        const index = state.foods.docs.findIndex(
          (todo) => todo._id === food._id
        );
        const newArray = [...state.foods.docs]; //making a new array
        newArray[index] = action.payload; //changing value in the new array
        const { docs, ...rest } = state.foods;
        const foods = {
          docs: newArray,
          ...rest,
        };
        return {
          ...state, //copying the orignal state
          foods,
        };
      }
      case ADD_FOOD: {
        const food = action.payload;
        return {
          ...state,
          food,
        };
      }
      case GET_FOODS: {
        const foodsArray = action.payload;
        return {
          ...state,
          foodsArray,
        };
      }
      default:
        return state;
    }
  } catch (error) {
    console.log(error);
  }
}
