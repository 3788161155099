import { ResponseUsersProps, User } from '../../../types/users//User';

export const LIST_USER = 'LIST_USER';
export const ADD_USERS = 'ADD_USERS';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const CHANGE_ACCOUNT = 'CHANGE_ACCOUNT';
export const UPDATE_USER_SIMPLE = 'UPDATE_USER_SIMPLE';

interface ListUser {
  type: typeof LIST_USER;
  payload: ResponseUsersProps;
}

interface AccionAgregarUser {
  type: typeof ADD_USER;
  payload: User;
}

interface ActionUpdateSimple {
  type: typeof UPDATE_USER;
  payload: User;
}

export type TypesActionsUsers =
  | ListUser
  | AccionAgregarUser
  | ActionUpdateSimple;
