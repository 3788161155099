import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';

const apiUrl = Constants.manifest.extra.REACT_APP_URL_BASE;

export const getToken = async () =>
    await AsyncStorage.getItem('user')
        ? JSON.parse(await AsyncStorage.getItem('user')).accessToken
        : null;

export const getAuthorizationHeader = async () => {
    const token = await getToken();
    return { Authorization: `Bearer ${token}` };
};

const axiosIntance = axios.create({
    baseURL: apiUrl,
    timeout: 30000
});

// For GET requests
axiosIntance.interceptors.request.use(
    async (config) => {
        const token = await AsyncStorage.getItem('user')
            ? JSON.parse(await AsyncStorage.getItem('user')).accessToken
            : null;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

// For POST requests
axiosIntance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        console.log("error response ", err);
        if (
            err.response && err.response?.status === 401 &&
            err.response?.data.message === 'Unauthorized'
        ) {
            await AsyncStorage.removeItem('user');
            await AsyncStorage.removeItem('notificationAssiatance');
            await AsyncStorage.removeItem('notificationOrders');
            return (window.location.href = '/');
        }
        return Promise.reject(err);
    }
);

export { axiosIntance };
