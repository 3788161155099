import {
  LIST_INVOICES,
  ADD_INVOICE,
  CLEAR_INVOICE,
  TypesActionsInvoices
} from './invoiceTypes';
import { InvoiceDao } from '../../..//api/invoice/dao/invoice.dao';
import { InvoiceRepository } from '../../..//api/invoice/repository/invoice.repository';
import { Alert } from 'react-native';
import { UserAuth } from '../../../types/users/User';
import {
  Invoice,
  ResponseInvoiceProps
} from '../../../types/payments/Payment';
import { InvoiceStatusEnum } from '../../../utils/enums';
import { updateUserAuth } from '../users/UsersActions';
import AsyncStorage from '@react-native-async-storage/async-storage';

function listInvoices(payload: ResponseInvoiceProps): TypesActionsInvoices {
  return {
    type: LIST_INVOICES,
    payload
  };
}

function addInvoce(payload: Invoice): TypesActionsInvoices {
  return {
    type: ADD_INVOICE,
    payload
  };
}

export function clearInvoice(): TypesActionsInvoices {
  return {
    type: CLEAR_INVOICE
  };
}

export async function getInvoiceStatusAsync(id: string) {
  try {
    const data = await InvoiceDao.getInvoiceStatus(id);
    if (!data.data.allowed) {
      Alert.alert(
        'Informacion',
        'El restaurante presenta morosidad por mensualidad vencida, por favor realizar el pago.',
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {
            },
          },
        ],
        { cancelable: false },
      );
    }
    return data;
  } catch ({ request }) {
    Alert.alert(
      'Error',
      `${JSON.parse(request.response).message}`,
      [
        {
          text: 'OK',
          style: 'cancel',
          onPress: () => {
          },
        },
      ],
      { cancelable: false },
    );
  }
}

export function makePaymentAllowedAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispatch: Function) {
    try {
      await InvoiceRepository.makePayment(form);
      const user: UserAuth = JSON.parse(await AsyncStorage.getItem('user'));
      user.allowedAccount.allowed = true;
      user.allowedAccount.invoice.status = InvoiceStatusEnum.PAID;
      AsyncStorage.setItem('user', JSON.stringify(user));
      dispatch(updateUserAuth(user));
      Alert.alert(
        'Hecho',
        `Pago realizado con éxito`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {
              callback()
            },
          },
        ],
        { cancelable: false },
      );
    } catch ({ request }) {
      const message = JSON.parse(request.response).message;
      if (message.message) {
        Alert.alert(
          'Error',
          message.message,
          [
            {
              text: 'OK',
              style: 'cancel',
              onPress: () => {
              },
            },
          ],
          { cancelable: false },
        );
      } else {
        Alert.alert(
          'Error',
          message,
          [
            {
              text: 'OK',
              style: 'cancel',
              onPress: () => {
              },
            },
          ],
          { cancelable: false },
        );
      }
      setValidate(true);
    }
  };
}

export function makePaymentAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispatch: Function) {
    try {
      await InvoiceRepository.makePayment(form);
      Alert.alert(
        'Hecho',
        'Pago realizado con éxito',
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {
              callback()
            },
          },
        ],
        { cancelable: false },
      );
    } catch ({ request }) {
      const message = JSON.parse(request.response).message;
      if (message.message) {
        Alert.alert(
          'Error',
          message.message,
          [
            {
              text: 'OK',
              style: 'cancel',
              onPress: () => {
              },
            },
          ],
          { cancelable: false },
        );
      } else {
        Alert.alert(
          'Error',
          message,
          [
            {
              text: 'OK',
              style: 'cancel',
              onPress: () => {
              },
            },
          ],
          { cancelable: false },
        );
      }
      setValidate(true);
    }
  };
}

export function listInvoicesAsync(
  page: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const invoices = await InvoiceDao.list(
      accountId,
      page,
      limit,
      search,
      filter
    );
    dispacth(listInvoices(invoices.data));
  };
}

export function showInvoiceAsync(id: string, callback: Function) {
  return async function (dispacth: Function) {
    try {
      const invoice = await InvoiceDao.show(id);
      dispacth(addInvoce(invoice.data));
    } catch ({ request }) {
      Alert.alert(
        'Error',
        `${JSON.parse(request.response).message}`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {
            },
          },
        ],
        { cancelable: false },
      );
      callback();
    }
  };
}

export async function getInvoicePdf(id: string) {
  try {
    return await InvoiceDao.getPdf(id);
  } catch ({ request }) {
    Alert.alert(
      'Error',
      `${JSON.parse(request.response).message}`,
      [
        {
          text: 'OK',
          style: 'cancel',
          onPress: () => {
          },
        },
      ],
      { cancelable: false },
    );
  }
}
