import {
  AccountingPurchase,
  ResponseAccountingPurchase,
} from '../../../types/accountingPurchase/accountingPurchase';

export const LIST_ACCOUNTING_PURCHASE = 'LIST_ACCOUNTING_PURCHASE';
export const SHOW_ACCOUNTING_PURCHASE = 'SHOW_ACCOUNTING_PURCHASE';
export const UPDATE_ACCOUNTING_PURCHASE = 'UPDATE_ACCOUNTING_PURCHASE';
export const DELETE_ACCOUNTING_PURCHASE = 'DELETE_ACCOUNTING_PURCHASE';

interface ActionListAccountingPurchase {
  type: typeof LIST_ACCOUNTING_PURCHASE;
  payload: ResponseAccountingPurchase;
}

interface ActionShowAccountingPurchase {
  type: typeof SHOW_ACCOUNTING_PURCHASE;
  payload: AccountingPurchase;
}

interface ActionUpdateAccountingPurchase {
  type: typeof UPDATE_ACCOUNTING_PURCHASE;
  payload: AccountingPurchase;
}

interface ActionDeleteAccountingPurchase {
  type: typeof DELETE_ACCOUNTING_PURCHASE;
  payload: string;
}

export type TypesActionsAccountingPurchase =
  | ActionListAccountingPurchase
  | ActionShowAccountingPurchase
  | ActionUpdateAccountingPurchase
  | ActionDeleteAccountingPurchase;
