import {
  TypesActionsOrders,
  LIST_ORDERS,
  LIST_ORDERS_CHECK_IN,
  ADD_ORDER,
  DELETE_ORDER,
  UPDATE_ORDER,
  SET_PANEL,
  LIST_ORDERS_HISTORY,
  ORDERS_TABLE,
  ORDER_PAYMENTS,
  SET_INVOICE_NUMBER,
  LIST_ORDER_INVOICES,
  UPDATE_ORDER_INVOCE,
  CLEAR_ORDERS_TABLE,
} from '../../actions/orders/OrdersTypes';
import { OrdersState } from '../../models/orders/OrdersState';
import { InvoiceStatusEnum, StatusOrder } from '../../../utils/enums';
import { Food } from '../../../types/foods/Food';
import { Order } from '../../../types/orders/Orders';
import { generalResponse } from '../..../../../../types/common/interfaces';
import {
  initialOrderTable,
  initialOrderValues,
} from '../../../types/orders/oldInterfaces';

const initialState: OrdersState = {
  panel: [],
  orders: generalResponse,
  ordersCheckIn: generalResponse,
  ordersHistory: generalResponse,
  order: initialOrderValues,
  ordersTable: initialOrderTable,
  orderPayments: generalResponse,
  invoiceNumber: { invoiceNumber: '', dgtKey: '' },
  orderInvoices: generalResponse,
};

export default function ordersReducer(
  state = initialState,
  action: TypesActionsOrders
) {
  switch (action.type) {
    case LIST_ORDERS: {
      const orders = action.payload;
      return {
        ...state,
        orders,
      };
    }
    case LIST_ORDERS_CHECK_IN: {
      const ordersCheckIn = action.payload;
      return {
        ...state,
        ordersCheckIn,
      };
    }
    case SET_INVOICE_NUMBER: {
      const data = action.payload;
      return {
        ...state,
        invoiceNumber: { ...data },
      };
    }
    case ORDER_PAYMENTS: {
      const orderPayments = action.payload;
      return {
        ...state,
        orderPayments,
      };
    }
    case LIST_ORDER_INVOICES: {
      const orderInvoices = action.payload;
      return {
        ...state,
        orderInvoices,
      };
    }
    case LIST_ORDERS_HISTORY: {
      const ordersHistory = action.payload;
      return {
        ...state,
        ordersHistory,
      };
    }
    case DELETE_ORDER: {
      const order = action.payload;
      return {
        ...state,
        orders: {
          docs: [...state.orders.docs.filter((e) => e._id !== order)],
        },
      };
    }
    case UPDATE_ORDER: {
      const order = action.payload;
      const index = state.orders.docs.findIndex(
        (todo) => todo._id === order._id
      );
      const newArray = [...state.orders.docs]; //making a new array
      newArray[index] = order; //changing value in the new array
      const { docs, ...rest } = state.orders;
      const orders = {
        docs: newArray, //reassingning todos to new array
        ...rest, //the rest of properties
      };

      let panel = [...state.panel];
      if (
        order.status === StatusOrder.COMPLETED ||
        order.status === StatusOrder.CANCELLED
      ) {
        panel = panel.filter((e) => e._id !== order._id);
      } else {
        const i = state.panel.findIndex((todo) => todo._id === order._id);
        panel[i] = order;
      }
      return {
        ...state, //copying the orignal state
        orders,
        panel,
      };
    }

    case UPDATE_ORDER_INVOCE: {
      const invoice = action.payload;
      invoice.status = InvoiceStatusEnum.PAID;
      const index = state.orderInvoices.docs.findIndex(
        (todo) => todo._id === invoice._id
      );
      const newArray = [...state.orderInvoices.docs]; //making a new array
      if (index !== -1) {
        newArray[index] = invoice; //changing value in the new array
      }
      const { docs, ...rest } = state.orderInvoices;
      const orderInvoices = {
        docs: newArray, //reassingning todos to new array
        ...rest, //the rest of properties
      };
      return {
        ...state, //copying the orignal state
        orderInvoices,
      };
    }

    case ADD_ORDER: {
      const order = action.payload;
      return {
        ...state,
        order,
      };
    }
    case SET_PANEL: {
      const order = action.payload;
      let nstate = state;
      nstate.panel = order;
      return {
        ...state,
        nstate,
      };
    }
    case ORDERS_TABLE: {
      const orders: Order[] = [];
      action.payload.orders?.forEach((o) => {
        const foodsAux: Food[] = [];
        let t = 0;
        o?.foods.forEach((f) => {
          if (!f.paymentStatus) {
            foodsAux.push(f);
            t += f.total;
          }
        });
        const { foods, total, ...rest } = o;
        const auxOrder = { ...rest, total: t, foods: foodsAux };
        orders.push(auxOrder);
      });
      return {
        ...state,
        ordersTable: { orders, table: action.payload.table },
      };
    }
    case CLEAR_ORDERS_TABLE: {
      return {
        ...state,
        ordersTable: initialOrderTable,
      };
    }
    default: {
      return state;
    }
  }
}
