import { Dashboard } from '../../../types/dashboard/DashBoard';

export const GET_DASHBOARD = 'GET_DASHBOARD';

interface ActionGetDashboard {
  type: typeof GET_DASHBOARD;
  payload: Dashboard;
}

export type TypesActionsDashboard = ActionGetDashboard;
