import { Recipe, ResponseRecipe } from '../../../types/recipe/Recipe';

export const LIST_RECIPE = 'LIST_RECIPE';
export const SHOW_RECIPE = 'SHOW_RECIPE';
export const UPDATE_RECIPE = 'UPDATE_RECIPE';
export const DELETE_RECIPE = 'DELETE_RECIPE';
export const GET_RECIPES = 'GET_RECIPES';

interface ActionListRecipe {
  type: typeof LIST_RECIPE;
  payload: ResponseRecipe;
}

interface ActionShowRecipe {
  type: typeof SHOW_RECIPE;
  payload: Recipe;
}

interface ActionUpdateRecipe {
  type: typeof UPDATE_RECIPE;
  payload: Recipe;
}

interface ActionDeleteRecipe {
  type: typeof DELETE_RECIPE;
  payload: string;
}

interface ActionGetRecipes {
  type: typeof GET_RECIPES;
  payload: ResponseRecipe;
}

export type TypesActionsRecipe =
  | ActionListRecipe
  | ActionShowRecipe
  | ActionUpdateRecipe
  | ActionDeleteRecipe
  | ActionGetRecipes;
