import {
  TypesActionsRecipe,
  LIST_RECIPE,
  SHOW_RECIPE,
  UPDATE_RECIPE,
  DELETE_RECIPE,
  GET_RECIPES,
} from '../../actions/recipe/RecipeTypes';
import { RecipeState } from '../../models/recipe/RecipeState';
import { RecipeInitialValues } from '../../../types/recipe/interfaces';
import { generalResponse } from '../../../types/common/interfaces';

const initialState: RecipeState = {
  recipes: generalResponse,
  recipe: RecipeInitialValues,
  recipesList: generalResponse,
};

export default function recipeReducer(
  state = initialState,
  action: TypesActionsRecipe
) {
  switch (action.type) {
    case LIST_RECIPE: {
      const recipes = action.payload;
      return {
        ...state,
        recipes,
      };
    }
    case GET_RECIPES: {
      const recipesList = action.payload;
      return {
        ...state,
        recipesList,
      };
    }
    case SHOW_RECIPE: {
      const recipe = action.payload;
      return {
        ...state,
        recipe,
      };
    }
    case UPDATE_RECIPE: {
      const recipe = action.payload;
      const index = state.recipes.docs.findIndex(
        (todo) => todo._id === recipe._id
      );
      const newArray = [...state.recipes.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.recipes;
      const recipes = {
        docs: newArray,
        ...rest,
      };
      return {
        ...state,
        recipes,
      };
    }
    case DELETE_RECIPE: {
      const id = action.payload;
      return {
        ...state,
        recipes: {
          docs: [...state.recipes.docs.filter((e) => e._id !== id)],
        },
      };
    }
    default: {
      return state;
    }
  }
}
