import { Decrease, ResponseDecrease } from '../../../types/decrease/Decrease';

export const LIST_DECREASE = 'LIST_DECREASE';
export const SHOW_DECREASE = 'SHOW_DECREASE';
export const UPDATE_DECREASE = 'UPDATE_DECREASE';
export const DELETE_DECREASE = 'DELETE_DECREASE';

interface ActionListDecrease {
  type: typeof LIST_DECREASE;
  payload: ResponseDecrease;
}

interface ActionShowDecrease {
  type: typeof SHOW_DECREASE;
  payload: Decrease;
}

interface ActionUpdateDecrease {
  type: typeof UPDATE_DECREASE;
  payload: Decrease;
}

interface ActionDeleteDecrease {
  type: typeof DELETE_DECREASE;
  payload: string;
}

export type TypesActionsDecrease =
  | ActionListDecrease
  | ActionShowDecrease
  | ActionUpdateDecrease
  | ActionDeleteDecrease;
