import { PlanInitialValues } from '../../../types/accounts/interfaces';
import {
  TypesActionsProvider,
  LIST_PROVIDER,
  SHOW_PROVIDER,
  UPDATE_PROVIDER,
  DELETE_PROVIDER,
} from '../../actions/provider/ProviderTypes';
import { ProviderState } from '../../models/provider/ProviderState';
import { ProviderInitialState } from '../../../types/provider/interfaces';

const initialState: ProviderState = {
  providers: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0,
  },
  provider: ProviderInitialState,
};

export default function providerReducer(
  state = initialState,
  action: TypesActionsProvider
) {
  switch (action.type) {
    case LIST_PROVIDER: {
      const providers = action.payload;
      return {
        ...state,
        providers,
      };
    }
    case SHOW_PROVIDER: {
      const provider = action.payload;
      return {
        ...state,
        provider,
      };
    }
    case UPDATE_PROVIDER: {
      const provider = action.payload;
      const index = state.providers.docs.findIndex(
        (todo) => todo._id === provider._id
      );
      const newArray = [...state.providers.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.providers;
      const providers = {
        docs: newArray,
        ...rest,
      };
      return {
        ...state,
        providers,
      };
    }
    case DELETE_PROVIDER: {
      const id = action.payload;
      return {
        ...state,
        providers: {
          docs: [...state.providers.docs.filter((e) => e._id !== id)],
        },
      };
    }
    default: {
      return state;
    }
  }
}
