import { Food, ResponseFoodsProps } from '../../../types/foods/Food';

export const LIST_FOODS = 'LIST_FOODS';
export const ADD_FOOD = 'ADD_FOOD';
export const DELETE_FOOD = 'DELETE_FOOD';
export const UPDATE_FOOD = 'UPDATE_FOOD';
export const UPLOAD_IMAGE_FOOD = 'UPLOAD_IMAGE_FOOD';
export const GET_FOODS = 'GET_FOODS';

interface ActionListFoods {
  type: typeof LIST_FOODS;
  payload: ResponseFoodsProps;
}

interface ActionAddFoods {
  type: typeof ADD_FOOD;
  payload: Food;
}

interface ActionDeleteFoods {
  type: typeof DELETE_FOOD;
  payload: string;
}
interface ActionUpdateFoods {
  type: typeof UPDATE_FOOD;
  payload: Food;
}

interface ActionUploadImageFood {
  type: typeof UPLOAD_IMAGE_FOOD;
  payload: Food;
}

interface ActionGetFoods {
  type: typeof GET_FOODS;
  payload: Food[];
}

export type TypesActionsFoods =
  | ActionListFoods
  | ActionAddFoods
  | ActionDeleteFoods
  | ActionUpdateFoods
  | ActionUploadImageFood
  | ActionGetFoods;
