import {
  CashRegister,
  ResponseCashRegister,
} from '../../../types/cashRegister/CashRegister';

export const LIST_CASH_REGISTER = 'LIST_CASH_REGISTER';
export const LIST_CASH_REGISTER2 = 'LIST_CASH_REGISTER2';
export const SHOW_CASH_REGISTER = 'SHOW_CASH_REGISTER';
export const UPDATE_CASH_REGISTER = 'UPDATE_CASH_REGISTER';
export const DELETE_CASH_REGISTER = 'DELETE_CASH_REGISTER';

interface ActionListCashRegister {
  type: typeof LIST_CASH_REGISTER;
  payload: ResponseCashRegister;
}

interface ActionListCashRegister2 {
  type: typeof LIST_CASH_REGISTER2;
  payload: ResponseCashRegister;
}

interface ActionShowCashRegister {
  type: typeof SHOW_CASH_REGISTER;
  payload: CashRegister;
}

interface ActionUpdateCashRegister {
  type: typeof UPDATE_CASH_REGISTER;
  payload: CashRegister;
}

interface ActionDeleteCashRegister {
  type: typeof DELETE_CASH_REGISTER;
  payload: string;
}

export type TypesActionsCashRegister =
  | ActionListCashRegister
  | ActionListCashRegister2
  | ActionShowCashRegister
  | ActionUpdateCashRegister
  | ActionDeleteCashRegister;
