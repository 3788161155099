import React, { useState, lazy } from 'react';
import {
  DrawerNavigationOptions,
  createDrawerNavigator,
} from '@react-navigation/drawer';
import { Platform, StyleSheet, View } from 'react-native';
import { UserAuth } from './src/types/users/User';
import { UsersRole } from './src/utils/enums';
import Loader from './src/components/SuspenseLoader';

const Sidebar = Loader(lazy(() => import('./src/components/Sidebar')));
const Header = Loader(lazy(() => import('./src/components/Header')));

const HomeScreen = Loader(lazy(() => import('./src/screens/HomeScreen')));

//Accounting Screens
const GeneralShoppingScreen = Loader(
  lazy(
    () =>
      import(
        './src/screens/accounting/GeneralShopping/Main/containers/GeneralShoppingScreen'
      )
  )
);
const GeneralSalesScreen = Loader(
  lazy(
    () =>
      import(
        './src/screens/accounting/GeneralSales/Main/containers/GeneralSalesScreen'
      )
  )
);
const OrdersHistory = Loader(
  lazy(() => import('./src/screens/accounting/GeneralSales/Orders/pages/Main'))
);
const TransactionOrdersScreen = Loader(
  lazy(
    () =>
      import('./src/screens/accounting/Transactions/OrderPayments/pages/Main')
  )
);
const OrderInvoices = Loader(
  lazy(() => import('./src/screens/accounting/OrderInvoices/Main/pages/Main'))
);

//Inventory Screens
const ProductsScreen = Loader(
  lazy(() => import('./src/screens/inventory/Products/Main/page/Main'))
);
const CreateProduct = Loader(
  lazy(() => import('./src/screens/inventory/Products/Create/pages/Main'))
);
const EditProduct = Loader(
  lazy(() => import('./src/screens/inventory/Products/Edit/pages/Main'))
);
const FoodCategoriesScreen = Loader(
  lazy(() => import('./src/screens/inventory/FoodCategories/Main/page/Main'))
);
const FoodCategoriesCreate = Loader(
  lazy(() => import('./src/screens/inventory/FoodCategories/Create/pages/Main'))
);
const FoodCategoriesEdit = Loader(
  lazy(() => import('./src/screens/inventory/FoodCategories/Edit/pages/Main'))
);
const FoodComplementsScreen = Loader(
  lazy(() => import('./src/screens/inventory/FoodComplements/Main/page/Main'))
);
const FoodComplementCreate = Loader(
  lazy(
    () => import('./src/screens/inventory/FoodComplements/Create/pages/Main')
  )
);
const FoodComplementEdit = Loader(
  lazy(() => import('./src/screens/inventory/FoodComplements/Edit/pages/Main'))
);
const DecreaseScreen = Loader(
  lazy(() => import('./src/screens/inventory/Decrease/Main/pages/Main'))
);
const CreateDecrease = Loader(
  lazy(() => import('./src/screens/inventory/Decrease/Create/pages/Main'))
);
const EditDecrease = Loader(
  lazy(() => import('./src/screens/inventory/Decrease/Edit/pages/Main'))
);
const ShoppingScreen = Loader(
  lazy(() => import('./src/screens/inventory/Shopping/Main/pages/Main'))
);
const SalesScreen = Loader(
  lazy(() => import('./src/screens/inventory/Sales/Main/pages/Main'))
);
const CreateGeneralShopping = Loader(
  lazy(
    () =>
      import(
        './src/screens/accounting/GeneralShopping/Create/CreateGeneralShopping'
      )
  )
);
const CreateShopping = Loader(
  lazy(() => import('./src/screens/inventory/Shopping/Create/pages/Main'))
);
const EditShopping = Loader(
  lazy(() => import('./src/screens/inventory/Shopping/Edit/pages/Main'))
);
const CreateSale = Loader(
  lazy(() => import('./src/screens/inventory/Sales/Create/pages/Main'))
);
const InventoryScreen = Loader(
  lazy(() => import('./src/screens/inventory/Inventory/Main/pages/Main'))
);
const CreateProductInventory = Loader(
  lazy(() => import('./src/screens/inventory/Inventory/Create/pages/Main'))
);
const EditProductInventory = Loader(
  lazy(() => import('./src/screens/inventory/Inventory/Edit/pages/Main'))
);
const InventoryMovements = Loader(
  lazy(
    () =>
      import('./src/screens/inventory/Inventory/InvetoryMovements/pages/Main')
  )
);
const InventoryMovementsCreate = Loader(
  lazy(
    () =>
      import(
        './src/screens/inventory/Inventory/CreateInventoryMovement/pages/Main'
      )
  )
);

// Providers
const ProviderMainScreen = Loader(
  lazy(() => import('./src/screens/accounting/Providers/Main/pages/Main'))
);
const ProviderCreateScreen = Loader(
  lazy(() => import('./src/screens/accounting/Providers/Create/pages/Main'))
);
const ProviderEditScreen = Loader(
  lazy(() => import('./src/screens/accounting/Providers/Edit/pages/Main'))
);

// Warehouses screems
const WarehouseMainScreen = Loader(
  lazy(() => import('./src/screens/inventory/Warehouse/Main/pages/Main'))
);
const WarehouseCreateScreen = Loader(
  lazy(() => import('./src/screens/inventory/Warehouse/Create/pages/Main'))
);
const WarehouseEditScreen = Loader(
  lazy(() => import('./src/screens/inventory/Warehouse/Edit/pages/Main'))
);

// Recipe screens
const RecipeMainScreen = Loader(
  lazy(() => import('./src/screens/inventory/Recipes/Main/pages/Main'))
);
const RecipeCreateScreen = Loader(
  lazy(() => import('./src/screens/inventory/Recipes/Create/pages/Main'))
);
const RecipeEditScreen = Loader(
  lazy(() => import('./src/screens/inventory/Recipes/Edit/pages/Main'))
);
const RecipeMainCreateScreen = Loader(
  lazy(() => import('./src/screens/inventory/Recipes/CreateMain/pages/Main'))
);
const RecipeMainEditScreen = Loader(
  lazy(() => import('./src/screens/inventory/Recipes/EditMain/pages/Main'))
);

//POS Screens

//Orders Screens

const PinFloorScreen = Loader(
  lazy(
    () =>
      import(
        './src/screens/pos/orders/PinMapFloorScreen/container/PinMapFloorScreen'
      )
  )
);
const MapFloorScreen = Loader(
  lazy(() => import('./src/screens/pos/orders/MapFloor/pages/Main'))
);
const CreateOrderScreen1 = Loader(
  lazy(() => import('./src/screens/pos/orders/CreateOrder/Create/pages/Main'))
);

//CheckIn Screens
const CheckInScreen = Loader(
  lazy(() => import('./src/screens/pos/CheckIn/CheckInScreen/pages/Main'))
);
const PinCheckInScreen = Loader(
  lazy(
    () =>
      import(
        './src/screens/pos/CheckIn/PinCheckInScreen/container/PinCheckInScreen'
      )
  )
);
const TablesDetailScreen = Loader(
  lazy(() => import('./src/screens/pos/CheckIn/TablesDetail/pages/Main'))
);

//CashRegister Screens
const CashRegisterMainScreen = Loader(
  lazy(() => import('./src/screens/config/cashRegister/Main/pages/Main'))
);
const CashRegisterCreateScreen = Loader(
  lazy(() => import('./src/screens/config/cashRegister/Create/pages/Main'))
);
const CashRegisterEditScreen = Loader(
  lazy(() => import('./src/screens/config/cashRegister/Edit/pages/Main'))
);
const PinCashRegisterScreen = Loader(
  lazy(
    () =>
      import(
        './src/screens/pos/CashRegister/PinCashRegisterScreen/container/PinCashRegisterScreen'
      )
  )
);
const OpenCashRegisterScreen = Loader(
  lazy(
    () => import('./src/screens/pos/CashRegister/OpenCashRegister/pages/Main')
  )
);
const HistoryCashRegister = Loader(
  lazy(
    () =>
      import('./src/screens/pos/CashRegister/HistoryCashRegister/pages/Main')
  )
);
const CashMovementsScreen = Loader(
  lazy(() => import('./src/screens/pos/CashRegister/CashMovements/pages/Main'))
);
const CreateCashMovementScreen = Loader(
  lazy(
    () => import('./src/screens/pos/CashRegister/CreateCashMovement/pages/Main')
  )
);

//Payroll
const PayrollMain = Loader(
  lazy(() => import('./src/screens/payroll/Payroll/Main/pages/Main'))
);

const Drawer = createDrawerNavigator();

interface RoutesProtectedProps {
  setAuthenticated: (value: React.SetStateAction<boolean>) => void;
  user: UserAuth;
}

interface DrawerScreen {
  name: string;
  component: any;
  options: DrawerNavigationOptions;
}

const suprimeDuplicateDrawer = (array: DrawerScreen[]) => {
  let newArray = array?.filter((item, index) => {
    return (
      index ===
      array.findIndex((obj) => {
        return obj.name === item.name;
      })
    );
  });
  return newArray;
};

const generalRoutes: DrawerScreen[] = [
  {
    name: 'PinMapFloorScreen',
    component: PinFloorScreen,
    options: { title: 'Validación de Pin' },
  },
  {
    name: 'MapFloor',
    component: MapFloorScreen,
    options: { title: 'Mapa de Piso' },
  },
  {
    name: 'CreateOrderScreen1',
    component: CreateOrderScreen1,
    options: { title: 'Crear Orden' },
  },
  {
    name: 'OrdersHistory',
    component: OrdersHistory,
    options: { title: 'Ventas Generales' },
  },
  {
    name: 'OrderInvoices',
    component: OrderInvoices,
    options: { title: 'Facturas' },
  },
];

const billRoutes: DrawerScreen[] = [
  {
    name: 'PinCheckInScreen',
    component: PinCheckInScreen,
    options: { title: 'Validación de Pin' },
  },
  {
    name: 'CheckInScreen',
    component: CheckInScreen,
    options: { title: 'Facturar' },
  },
  {
    name: 'TableDetailScreen',
    component: TablesDetailScreen,
    options: { title: 'Facturar' },
  },
  {
    name: 'PinCashRegisterScreen',
    component: PinCashRegisterScreen,
    options: { title: 'Validación de Pin' },
  },
  {
    name: 'OpenCashRegisterScreen',
    component: OpenCashRegisterScreen,
    options: { title: 'Caja' },
  },
  {
    name: 'CashRegisterHistory',
    component: HistoryCashRegister,
    options: { title: 'Historial de Caja' },
  },
  {
    name: 'CashMovements',
    component: CashMovementsScreen,
    options: { title: 'Movimientos de Efectivo' },
  },
  {
    name: 'CashMovementCreate',
    component: CreateCashMovementScreen,
    options: { title: 'Crear Movimiento de Efectivo' },
  },
];

const inventoryRoutes: DrawerScreen[] = [
  {
    name: 'Warehouses',
    component: WarehouseMainScreen,
    options: { title: 'Almacenes' },
  },
  {
    name: 'CreateWarehouse',
    component: WarehouseCreateScreen,
    options: { title: 'Crear Almacén' },
  },
  {
    name: 'WarehouseEdit',
    component: WarehouseEditScreen,
    options: { title: 'Editar Almacén' },
  },
  {
    name: 'Inventory',
    component: InventoryScreen,
    options: { title: 'Inventario' },
  },
  {
    name: 'CreateProductInventory',
    component: CreateProductInventory,
    options: { title: 'Crear Producto en Inventario' },
  },
  {
    name: 'EditProductInventory',
    component: EditProductInventory,
    options: { title: 'Editar Producto en Inventario' },
  },
  {
    name: 'InventoryMovements',
    component: InventoryMovements,
    options: { title: 'Movimientos de Inventario' },
  },
  {
    name: 'InventoryMovementsCreate',
    component: InventoryMovementsCreate,
    options: { title: 'Registrar Movimientos de Inventario' },
  },
  {
    name: 'Products',
    component: ProductsScreen,
    options: { title: 'Productos' },
  },
  {
    name: 'CreateProduct',
    component: CreateProduct,
    options: { title: 'Crear Producto' },
  },
  {
    name: 'EditProduct',
    component: EditProduct,
    options: { title: 'Editar Producto' },
  },
  {
    name: 'FoodCategories',
    component: FoodCategoriesScreen,
    options: { title: 'Categorías de Productos' },
  },
  {
    name: 'FoodCategoriesCreate',
    component: FoodCategoriesCreate,
    options: { title: 'Crear Categoría' },
  },
  {
    name: 'FoodCategoriesEdit',
    component: FoodCategoriesEdit,
    options: { title: 'Editar Categoría' },
  },
  {
    name: 'FoodComplements',
    component: FoodComplementsScreen,
    options: { title: 'Acompañamientos de Productos' },
  },
  {
    name: 'FoodComplementsCreate',
    component: FoodComplementCreate,
    options: { title: 'Crear Acompañamiento' },
  },
  {
    name: 'FoodComplementsEdit',
    component: FoodComplementEdit,
    options: { title: 'Editar Acompañamiento' },
  },
  {
    name: 'Recipes',
    component: RecipeMainScreen,
    options: { title: 'Recetas' },
  },
  {
    name: 'CreateRecipeMain',
    component: RecipeMainCreateScreen,
    options: { title: 'Crear Receta' },
  },
  {
    name: 'CreateRecipe',
    component: RecipeCreateScreen,
    options: { title: 'Crear Sub-Receta' },
  },
  {
    name: 'RecipeMainEdit',
    component: RecipeMainEditScreen,
    options: { title: 'Editar Receta' },
  },
  {
    name: 'RecipeEdit',
    component: RecipeEditScreen,
    options: { title: 'Editar Sub-Receta' },
  },
  {
    name: 'Decrease',
    component: DecreaseScreen,
    options: { title: 'Merma' },
  },
  {
    name: 'CreateDecrease',
    component: CreateDecrease,
    options: { title: 'Crear Merma' },
  },
  {
    name: 'EditDecrease',
    component: EditDecrease,
    options: { title: 'Editar Merma' },
  },
  {
    name: 'Shopping',
    component: ShoppingScreen,
    options: { title: 'Compras' },
  },
  {
    name: 'CreateShopping',
    component: CreateShopping,
    options: { title: 'Crear Compra' },
  },
  {
    name: 'EditShopping',
    component: EditShopping,
    options: { title: 'Editar Compra' },
  },
  {
    name: 'Sales',
    component: SalesScreen,
    options: { title: 'Ventas' },
  },
  {
    name: 'CreateSale',
    component: CreateSale,
    options: { title: 'Crear Venta' },
  },
];

const operationsRoutes: DrawerScreen[] = [
  {
    name: 'PinCashRegisterScreen',
    component: PinCashRegisterScreen,
    options: { title: 'Validación de Pin' },
  },
  {
    name: 'OpenCashRegisterScreen',
    component: OpenCashRegisterScreen,
    options: { title: 'Caja' },
  },
  {
    name: 'CashRegisterHistory',
    component: HistoryCashRegister,
    options: { title: 'Historial de Caja' },
  },
  {
    name: 'CashMovements',
    component: CashMovementsScreen,
    options: { title: 'Movimientos de Efectivo' },
  },
  {
    name: 'CashMovementCreate',
    component: CreateCashMovementScreen,
    options: { title: 'Crear Movimiento de Efectivo' },
  },
];

const payrollRoutes: DrawerScreen[] = [
  {
    name: 'PayrollMainScreen',
    component: PayrollMain,
    options: { title: 'Planilla' },
  },
];

const administrationRoutes: DrawerScreen[] = [
  {
    name: 'Providers',
    component: ProviderMainScreen,
    options: { title: 'Proveedores' },
  },
  {
    name: 'CreateProvider',
    component: ProviderCreateScreen,
    options: { title: 'Crear Proveedor' },
  },
  {
    name: 'ProviderEdit',
    component: ProviderEditScreen,
    options: { title: 'Editar Proveedor' },
  },
  {
    name: 'GeneralShopping',
    component: GeneralShoppingScreen,
    options: { title: 'Compras Generales' },
  },
  {
    name: 'GeneralShoppingCreate',
    component: CreateGeneralShopping,
    options: { title: 'Crear Compra General' },
  },
  {
    name: 'EditShopping',
    component: EditShopping,
    options: { title: 'Editar Compra' },
  },
  {
    name: 'GeneralSales',
    component: GeneralSalesScreen,
    options: { title: 'Ventas Generales' },
  },
  {
    name: 'OrdersHistory',
    component: OrdersHistory,
    options: { title: 'Ventas Generales' },
  },
  {
    name: 'CashRegisterMain',
    component: CashRegisterMainScreen,
    options: { title: 'Caja Registradora' },
  },
  {
    name: 'CashRegisterCreate',
    component: CashRegisterCreateScreen,
    options: { title: 'Caja Registradora' },
  },
  {
    name: 'CashRegisterEdit',
    component: CashRegisterEditScreen,
    options: { title: 'Caja Registradora' },
  },
  {
    name: 'Transactions',
    component: TransactionOrdersScreen,
    options: { title: 'Transacciones' },
  },
  // {
  //   name: 'OrderInvoices',
  //   component: OrderInvoices,
  //   options: { title: 'Facturas' },
  // },
  {
    name: 'PayrollMainScreen',
    component: PayrollMain,
    options: { title: 'Planilla' },
  },
];

const RoutesProtected: React.FC<RoutesProtectedProps> = ({
  setAuthenticated,
  user,
}) => {
  let screen: any = null;
  if (Platform.OS === 'web') {
    screen = document.fullscreenElement;
  }
  const [fullScreen, setFullScreen] = useState(screen ? true : false);
  const [showAttendance, setShowAttendance] = useState(false);

  function launchFullScreen(element) {
    if (Platform.OS === 'web') {
      if (element.requestFullScreen) {
        element.requestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      }
    }
  }
  // Lanza en pantalla completa en navegadores que lo soporten
  function cancelFullScreen(document) {
    if (Platform.OS === 'web') {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const handleScreen = () => {
    if (Platform.OS === 'web') {
      if (fullScreen) {
        cancelFullScreen(document);
      } else {
        launchFullScreen(document.documentElement);
      }
      setFullScreen(!fullScreen);
    }
  };

  const getHeader = () => (
    <Header
      fullScreen={fullScreen}
      handleScreen={handleScreen}
      setAuthenticated={setAuthenticated}
    />
  );

  const getRoutes = () => {
    let routes: DrawerScreen[] = [];
    if (user.role === UsersRole.OWNER) {
      const aux = suprimeDuplicateDrawer([
        ...generalRoutes,
        ...billRoutes,
        ...inventoryRoutes,
        ...operationsRoutes,
        ...administrationRoutes,
        ...payrollRoutes,
      ]);
      routes = [...aux];
    } else {
      if (
        user.permissionsPOS &&
        JSON.stringify(user.permissionsPOS) !== JSON.stringify({})
      ) {
        const permissions = user.permissionsPOS;
        if (permissions.general && permissions.general.length) {
          routes = [...routes, ...generalRoutes];
        }
        if (permissions.bill && permissions.bill.length) {
          routes = [...routes, ...billRoutes];
        }
        if (permissions.inventory && permissions.inventory.length) {
          routes = [...routes, ...inventoryRoutes];
        }
        if (permissions.operations && permissions.operations.length) {
          routes = [...routes, ...operationsRoutes];
        }
        if (permissions.administration && permissions.administration.length) {
          routes = [...routes, ...administrationRoutes];
        }
        if (permissions.payroll && permissions.payroll.length) {
          routes = [...routes, ...payrollRoutes];
        }
        const aux = suprimeDuplicateDrawer(routes);
        routes = [...aux];
      }
    }
    return routes;
  };
  return (
    <Drawer.Navigator
      drawerContent={(props) => <Sidebar {...props} />}
      screenOptions={{
        headerRight: (props) => getHeader(),
        lazy: true,
      }}
    >
      <Drawer.Screen
        name="Dashboard"
        component={HomeScreen}
        options={{ title: 'Inicio' }}
      />
      {getRoutes()?.map((e, i) => {
        return (
          <Drawer.Screen
            key={e.name + i}
            name={e.name}
            component={e.component}
            options={e.options}
          />
        );
      })}
    </Drawer.Navigator>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default RoutesProtected;
