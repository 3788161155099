import {
  FoodComplement,
  ResponseFoodComplementsProps,
} from '../../../types/foodComplements/FoodComplement';

export const LIST_FOOD_COMPLEMENTS = 'LIST_FOOD_COMPLEMENTS';
export const ADD_FOOD_COMPLEMENT = 'ADD_FOOD_COMPLEMENT';
export const DELETE_FOOD_COMPLEMENT = 'DELETE_FOOD_COMPLEMENT';
export const UPDATE_FOOD_COMPLEMENT = 'UPDATE_FOOD_COMPLEMENT';
export const UPLOAD_IMAGE_FOOD_COMPLEMENT = 'UPLOAD_IMAGE_FOOD_COMPLEMENT';
export const GET_LIST_FOOD_COMPLEMENT = 'GET_LIST_FOOD_COMPLEMENT';

interface ActionListFoodComplements {
  type: typeof LIST_FOOD_COMPLEMENTS;
  payload: ResponseFoodComplementsProps;
}

interface ActionAddFoodComplements {
  type: typeof ADD_FOOD_COMPLEMENT;
  payload: FoodComplement;
}

interface ActionDeleteFoodComplements {
  type: typeof DELETE_FOOD_COMPLEMENT;
  payload: string;
}
interface ActionUpdateFoodComplements {
  type: typeof UPDATE_FOOD_COMPLEMENT;
  payload: FoodComplement;
}

interface ActionUploadImageFoodComplement {
  type: typeof UPLOAD_IMAGE_FOOD_COMPLEMENT;
  payload: FoodComplement;
}

interface ActionGetListFoodComplement {
  type: typeof GET_LIST_FOOD_COMPLEMENT;
  payload: FoodComplement[];
}

export type TypesActionsFoodComplements =
  | ActionListFoodComplements
  | ActionAddFoodComplements
  | ActionDeleteFoodComplements
  | ActionUpdateFoodComplements
  | ActionUploadImageFoodComplement
  | ActionGetListFoodComplement;
