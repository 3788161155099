import { AxiosResponse } from 'axios';

import { axiosIntance } from '../../../config/ApiConfig';

export const UserDao = {
  SignIn: async (form) => await axiosIntance.post(`/auth/login`, form),
  list: (
    page: number | string | null,
    limit: number | string | null,
    search: string | null
  ) => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }

    if (!page) {
      page = 0;
    }
    if (!limit) {
      limit = 5;
    }
    return axiosIntance.get(`/users?page=${page}&limit=${limit}${query}`);
  },
  show: (id: string) => {
    return axiosIntance.get(`/users/${id}`);
  },
};
