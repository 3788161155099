import {
  Warehouse,
  ResponseWarehouse,
} from '../../../types/warehouse/Warehouse';

export const LIST_WAREHOUSE = 'LIST_WAREHOUSE';
export const SHOW_WAREHOUSE = 'SHOW_WAREHOUSE';
export const UPDATE_WAREHOUSE = 'UPDATE_WAREHOUSE';
export const DELETE_WAREHOUSE = 'DELETE_WAREHOUSE';

interface ActionListWarehouse {
  type: typeof LIST_WAREHOUSE;
  payload: ResponseWarehouse;
}

interface ActionShowWarehouse {
  type: typeof SHOW_WAREHOUSE;
  payload: Warehouse;
}

interface ActionUpdateWarehouse {
  type: typeof UPDATE_WAREHOUSE;
  payload: Warehouse;
}

interface ActionDeleteWarehouse {
  type: typeof DELETE_WAREHOUSE;
  payload: string;
}

export type TypesActionsWarehouse =
  | ActionListWarehouse
  | ActionShowWarehouse
  | ActionUpdateWarehouse
  | ActionDeleteWarehouse;
