import {
  Inventory,
  ResponseInventory,
  ResponseInventoryMovements,
} from '../../../types/inventory/Inventory';

export const LIST_INVENTORY = 'LIST_INVENTORY';
export const LIST_INVENTORY2 = 'LIST_INVENTORY2';
export const LIST_INVENTORY_MOVEMENTS = 'LIST_INVENTORY_MOVEMENTS';
export const SHOW_INVENTORY = 'SHOW_INVENTORY';
export const UPDATE_INVENTORY = 'UPDATE_INVENTORY';
export const DELETE_INVENTORY = 'DELETE_INVENTORY';

interface ActionListInventory {
  type: typeof LIST_INVENTORY;
  payload: ResponseInventory;
}

interface ActionListInventory2 {
  type: typeof LIST_INVENTORY2;
  payload: ResponseInventory;
}

interface ActionListInventoryMovements {
  type: typeof LIST_INVENTORY_MOVEMENTS;
  payload: ResponseInventoryMovements;
}

interface ActionShowInventory {
  type: typeof SHOW_INVENTORY;
  payload: Inventory;
}

interface ActionUpdateInventory {
  type: typeof UPDATE_INVENTORY;
  payload: Inventory;
}

interface ActionDeleteInventory {
  type: typeof DELETE_INVENTORY;
  payload: string;
}

export type TypesActionsInventory =
  | ActionListInventory
  | ActionListInventory2
  | ActionListInventoryMovements
  | ActionShowInventory
  | ActionUpdateInventory
  | ActionDeleteInventory;
