import { PlanInitialValues } from '../../../types/accounts/interfaces';
import {
  TypesActionsDecrease,
  LIST_DECREASE,
  SHOW_DECREASE,
  UPDATE_DECREASE,
  DELETE_DECREASE,
} from '../../actions/decrease/DecreaseTypes';
import { DecreaseState } from '../../models/decrease/DecreaseState';
import { DecreaseInitialValues } from '../../../types/decrease/interfaces';
import { generalResponse } from '../../../types/common/interfaces';

const initialState: DecreaseState = {
  decreases: {
    ...generalResponse,
    totalAmount: 0,
  },
  decrease: DecreaseInitialValues,
};

export default function decreaseReducer(
  state = initialState,
  action: TypesActionsDecrease
) {
  switch (action.type) {
    case LIST_DECREASE: {
      const decreases = action.payload;
      return {
        ...state,
        decreases,
      };
    }
    case SHOW_DECREASE: {
      const decrease = action.payload;
      return {
        ...state,
        decrease,
      };
    }
    case UPDATE_DECREASE: {
      const decrease = action.payload;
      const index = state.decreases.docs.findIndex(
        (todo) => todo._id === decrease._id
      );
      const newArray = [...state.decreases.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.decreases;
      const decreases = {
        docs: newArray,
        ...rest,
      };
      return {
        ...state,
        decreases,
      };
    }
    case DELETE_DECREASE: {
      const id = action.payload;
      return {
        ...state,
        decreases: {
          docs: [...state.decreases.docs.filter((e) => e._id !== id)],
        },
      };
    }
    default: {
      return state;
    }
  }
}
