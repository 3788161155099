import { ResponsePayroll } from '../../../types/payroll/Payroll';

export const LIST_PAYROLLS = 'LIST_PAYROLLS';

interface ActionListPayroll {
  type: typeof LIST_PAYROLLS;
  payload: ResponsePayroll;
}

export type TypesActionsPayroll = ActionListPayroll;
