export enum CategoryTypes {
  DEFAULT = 'default',
  CUSTOM = 'custom',
}
export enum CategoryStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum CategoryStatusSpanish {
  ACTIVE = 'activo',
  INACTIVE = 'inactivo',
}

export enum UsersStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum UsersStatusSpanish {
  ACTIVE = 'activo',
  INACTIVE = 'inactivo',
}

export enum UsersRole {
  SUPERADMIN = 'superadmin',
  ADMIN = 'admin',
  OWNER = 'owner',
  MANAGER = 'manager',
  KITCHEN = 'kitchen',
  HOST = 'host',
}

export enum ComplementsStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum ComplementsStatusSpanish {
  ACTIVE = 'activo',
  INACTIVE = 'inactivo',
}

export enum AccountStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum AccountStatusEnumSpanish {
  ACTIVE = 'ACTIVO',
  INACTIVE = 'INACTIVO',
}

export enum IsEnableEnum {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
}

export enum IsEnableEnumSpanish {
  ENABLE = 'HABILITADO',
  DISABLE = 'INHABILITADO',
}

export enum IsEnableTableEnum {
  ENABLE = 'ACTIVE',
  DISABLE = 'INACTIVE',
}

export enum IsEnableTableEnumSpanish {
  ENABLE = 'ACTIVO',
  DISABLE = 'INACTIVO',
}

export enum StatusOrder {
  GENERATED = 'Generada',
  INPREPARATION = 'En preparación',
  COMPLETED = 'Completada',
  CANCELLED = 'Anulada',
  CHANGE_FOOD_STATUS = 'Entregado',
  SAVED = 'Guardada',
  DELETED = 'Deleted',
}

export enum SpecialQueries {
  queryType = 'OrdersCards',
}

export enum DateSelectorEnum {
  Today = 'TODAY',
  LastSevenDays = 'LASTSEVENDAYS',
  Range = 'RANGE',
  Month = 'MONTH',
}

export enum ReportSelectorEnum {
  orders = 'orders',
  sales = 'sales',
}

export enum PaymentOrderStatus {
  PENDING = 'Pendiente',
  COMPLETED = 'Completada',
  CANCELLED = 'Cancelled',
}

export enum RefreshIntervals {
  FIVESECONS = '5000',
  TENSECONDS = '10000',
  THIRTYSECONDS = '30000',
  SIXTYSECONDS = '60000',
}

export enum OrderMaxTimes {
  FIVEMINUTES = '300000',
  TENMINUTES = '600000',
  FIXTEENMINUTES = '900000',
  TWENTYMINUTES = '1200000',
  TWENTYFIVEMINUTES = '1500000',
  THIRTYMINUTES = '1800000',
  THIRTYFIVEMINUTES = '2100000',
  FORTYMINUTES = '2400000',
  FORTYFIVEMINUTES = '2700000',
  FIXTYMINUTES = '3000000',
  FIXTYFIVEMINUTES = '3300000',
  SIXTYMINUTES = '3600000',
}

export enum CustomFilterTypes {
  SELECT = 'select',
  INPUT_TEXT = 'input_text',
  INPUT_DATE = 'input_DATE',
  MULTI_SELECT = 'multi_select',
  TABS = 'tabs',
}

export enum sectionsFilters {
  ORDERS = 'orders',
}

export enum InvoiceStatusEnum {
  PAID = 'paid',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  SENT = 'sent',
}

export enum InvoiceStatusEnumSpanish {
  PAID = 'Pagado',
  PENDING = 'Pendiente',
  CANCELLED = 'Anulada',
  SENT = 'Enviada',
}

export enum TypeDocumentIdSpanish {
  CI = 'fisica',
  CJ = 'juridica',
  DIMEX = 'dimex',
  NITE = 'nite',
}

export enum ElectronicInvoice {
  pending = 'pending',
  generated = 'generated',
}

export enum ElectronicInvoiceSpanish {
  pending = 'pendiente',
  generated = 'generada',
}

export enum ElectronicInvoiceOptions {
  all = 'all',
  pending = 'pending',
  generated = 'generated',
}

export enum ElectronicInvoiceOptionsSpanish {
  all = 'Todas',
  pending = 'Pendiente',
  generated = 'Generada',
}

export enum SettingTypes {
  KITCHEN = 'kitchen',
}

export enum TableStatus {
  AVAILABLE = 'available',
  BUSY = 'busy',
  RESERVED = 'reserved',
}

export enum TypePayment {
  SINPE_PAY = 'sinpePay',
  SPLIT_ACCOUNT = 'splitAccount',
  CARD = 'tarjeta',
  CASH = 'efectivo',
  PENDING = 'pendiente',
  PROMO_CODE = 'promoCode',
  LOYALTI = 'loyalti',
  QR_CODE = 'qrCode',
}

export enum CardType {
  VISA = 'visa',
  MASTER_CARD = 'masterCard',
  AMERICAN_EXPRESS = 'americanExpress',
  OTHER = 'other',
}

export enum PaymentType {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export enum OrderTypes {
  INSITE = 'en el lugar',
  DELIVERY = 'delivery',
  TOGO = 'para llevar',
  ANTICIPADET = 'anticipado',
}

export enum ProductUnits {
  KILOS = 'Kilogramos',
  GRAMS = 'Gramos',
  LITRES = 'Litros',
  MILILITRES = 'Mililitros',
  PART = 'Unidad',
  PORTION = 'Porción',
  ROLLS = 'Rollo',
  SHOT = 'Shot',
  METER = 'Metros',
}

export enum ProductCategory {
  FRUITS = 'Frutas',
  VEGETABLES = 'Verduras',
  MEATS = 'Carnes',
  BIRDS = 'Aves',
  FLOURS = 'Harinas',
  GRAINS = 'Granos',
  DRINKS = 'Bebidas',
  SAUCES = 'Salsas',
  CONDIMENTS = 'Condimentos',
  FISH = 'Pescados',
  OILS = 'Aceites',
  SEEDS = 'Semillas',
  BAKERY = 'Panadería',
  DAIRY = 'Lácteos',
  SEAFOOD = 'Mariscos',
  LIQUOR = 'Licor',
  OTHERS = 'Otros',
}

export enum ProductTypes {
  INGREDIENTS = 'Ingredientes',
  SUPPLIES = 'Insumos',
  UTENSILS = 'Utensilios',
  FURNITURE = 'Mobiliarios',
  EQUIPMENT = 'Equipos',
}

export enum ProductStatus {
  ACTIVE = 'activo',
  INACTIVE = 'inactivo',
}

export enum DecreaseType {
  FOOD = 'Producto',
  PRODUCT = 'Ingrediente en inventario',
}

export enum RecipeType {
  MAIN = 'Receta',
  SUB = 'Sub-Receta',
}

export enum IngredientType {
  SUB_RECIPE = 'Sub Receta',
  PRODUCT = 'Producto de inventario',
}

export enum PurchaseType {
  P = 'P',
  PO = 'PO',
}

export enum PurchaseTypeLabel {
  P = 'Compra',
  PO = 'Orden de Compra',
}

export enum PurchaseStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum PurchaseStatusSpanish {
  OPEN = 'Abierta',
  CLOSED = 'Cerrada',
}

export enum CashRegisterType {
  OPEN = 'open',
  CLOSE = 'close',
}

export enum PinMessage {
  AUTHORIZED = 'Authorized',
  INVALID_PIN = 'Invalid Pin',
  UNAUTHORIZED = 'Cash Register Unauthorized',
  CASH_REGISTER_FAIL = 'cash Register Movement opened not exist',
  INVALID_PIN_NUMBER = 'securityCode must be a number string',
}

export enum CreditCards {
  VISA = 'Visa',
  AMEX = 'American Express',
  MAESTRO = 'Maestro',
  DINERS = 'Diners Club',
  DISCOVER = 'Discover',
  ELO = 'Elo',
  HIPERCARD = 'Hipercard',
  JCB = 'JCB',
  MASTERCARD = 'Mastercard',
  UNIONPAY = 'UnionPay',
}

export enum PaymentTypes {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export enum PaymentMethods {
  CASH = 'efectivo',
  CARD = 'tarjeta',
}

export enum InventoryType {
  IN = 'in',
  OUT = 'out',
}

export enum InventoryTypeSpanish {
  IN = 'Entrada',
  OUT = 'Salida',
}

export enum InventoryTypeMovement {
  CREATE_PRODUCT = 'create_product',
  ORDER = 'order',
  SALES = 'sales',
  ADJUSTMENT = 'adjustment',
  MERMA = 'merma',
}

export enum InventoryTypeMovementSpanish {
  CREATE_PRODUCT = 'Crear Producto',
  ORDER = 'Orden',
  SALES = 'Venta',
  ADJUSTMENT = 'Ajuste',
  MERMA = 'Merma',
}

export enum InventoryTypeOrigen {
  decreases = 'Merma',
  order = 'Orden',
  purchase = 'Compra',
  sales = 'Venta',
}

export enum FoodRecipeType {
  food = 'Producto',
  foodComplement = 'Acompañamiento',
}

export enum CashMovementOut {
  RETURN = 'Devolución',
  PAYMENT_PROVIDER = 'Pago Proveedor',
  PAYROLL = 'Pago Planilla',
  ADDITIONAL_PURCHASE = 'Compra adicional',
  OTHERS = 'Otros',
}

export enum CashMovementIn {
  SALE = 'Venta',
  ADDITIONAL_SERVICE = 'Servicio adicional',
  ADDITIONAL_PRODUCT = 'Producto Adicional',
  OTHERS = 'Otros',
}

export enum CashMovementType {
  IN = 'in',
  OUT = 'out',
}

export enum CashMovementTypeSpanish {
  IN = 'Entrada',
  OUT = 'Salida',
}

export enum RegimenTypes {
  SIMPLIFIED = 'Simplificado',
  TRADITIONAL = 'Tradicional',
}

export enum RemoveFoodReason {
  ERROR = 'Error de digitación',
  LOSS = 'Merma',
}

export enum ReferencesEnum {
  inventory = 'INGREDIENTE',
  recipe = 'RECETA',
  subRecipe = 'SUB RECETA',
  provider = 'PROVEEDOR',
  purchase = 'COMPRA',
  purchaseOrder = 'ORDEN DE COMPRA',
  create = 'CREAR',
  edit = 'EDITAR',
}
