import { Sale, ResponseSales } from '../../../types/sales/Sales';

export const LIST_SALE = 'LIST_SALE';
export const SHOW_SALE = 'SHOW_SALE';
export const UPDATE_SALE = 'UPDATE_SALE';
export const DELETE_SALE = 'DELETE_SALE';
// export const GET_SALES = 'GET_SALES';

interface ActionListSale {
  type: typeof LIST_SALE;
  payload: ResponseSales;
}

interface ActionShowSale {
  type: typeof SHOW_SALE;
  payload: Sale;
}

interface ActionUpdateSale {
  type: typeof UPDATE_SALE;
  payload: Sale;
}

interface ActionDeleteSale {
  type: typeof DELETE_SALE;
  payload: string;
}

// interface ActionGetSales {
//   type: typeof GET_SALES;
//   payload: ResponseSales;
// }

export type TypesActionsSale =
  | ActionListSale
  | ActionShowSale
  | ActionUpdateSale
  | ActionDeleteSale
  // | ActionGetSales;
